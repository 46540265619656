<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Clientes > Grupo Clientes Desceuntos</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4>
        <strong>Listado de Grupos de Clientes Descuentos </strong>
      </h4>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-2">
          <label for="grupo_id"><strong>GRUPO ID:</strong></label>
          <InputText
            v-model="grupo_id"
            placeholder="Grupo ID"
            readonly
          ></InputText>
        </div>
        <div class="field col-12 md:col-8">
          <label for=""><strong>BUSCAR CLIENTE:</strong></label>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="clienteSelected"
            :suggestions="clienteListadoFiltrado"
            @complete="buscarCliente($event)"
            placeholder="Buscar Cliente"
            :delay="500"
            @item-select="agregarClienteAlDetalle"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }} *** <strong>NIT: </strong
                >{{ slotProps.item.ci_nit }}
              </div>
            </template>
          </AutoComplete>
          <small class="p-invalid" v-if="errors.clienteSelected">{{
            errors.clienteSelected[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="fecha_movimiento"><strong>ACCIONES:</strong></label>
          <Button
            label="AGREGAR CLIENTE"
            icon="pi pi-plus"
            class="p-button-primary"
            v-tooltip.top="'Agregar Cliente al Grupo'"
            @click="agregarClienteAlDetalle"
          />
        </div>
      </div>
      <div class="mt-4 col-12">
        <DataTable
          ref="dtgrupo_desc_cliente"
          key="id"
          :value="detalleGrupoClientes"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          responsive="true"
          :filters="buscar"
          showGridlines
        >
        <template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar..."
							/>
						</span>
					</div>
				</template>
          <template #loading
            ><div class="flex align-items-center justify-content-center">
              <ProgressSpinner /></div
          ></template>
          <template #empty>
            <div class="flex align-items-center justify-content-center">
              <div class="p-invalid">No existen datos para mostrar</div>
            </div>
          </template>
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="id" header="ID"> </Column>
          <Column field="nombre" header="NOMBRE/RAZON SOCIAL"> </Column>
          <Column field="ci_nit" header="CI/NIT"></Column>
          <Column field="telefono" header="TELEFONO"></Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                @click="quitarClienteAlDetalle(slotProps)"
                v-tooltip.top="'Quitar Cliente del Grupo'"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex col-12 justify-content-end mt-2">
        <Button
          @click="goBack"
          class="p-button-danger mr-2"
          label="VOLVER"
          icon="pi pi-arrow-left"
        />
        <Button
          label="GUARDAR"
          icon="pi pi-save"
          class="p-button-primary"
          v-tooltip.top="'Guardar Grupo de Clientes'"
          @click="guardarGrupo"
          :loading="enviando"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ClienteService from "@/service/ClienteService";
export default {
  data() {
    return {
      grupo_id: this.$route.params.id,
      detalleGrupoClientes: [],
      clienteSelected: null,
      clienteListadoFiltrado: [],
      enviando: false,
      errors: {},
      buscar: {
        global: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
    };
  },
  clienteService: null,
  created() {
    this.clienteService = new ClienteService();
  },
  mounted() {
    this.detalleGrupoDesCliente();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    validarFormulario() {
      if (this.detalleGrupoClientes.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar al menos un cliente al grupo",
          life: 3000,
        });
        return false;
      }
      return true;
    },
    guardarGrupo() {
      if (this.validarFormulario()) {
        this.enviando = true;
      }
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁS SEGURO DE GUARDAR EL GRUPO DE CLIENTES?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Guardar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.guardando = false;
        },
        accept: () => {
          let datos = {
            grupo_id: this.grupo_id,
            detalle: this.detalleGrupoClientes,
          };
          this.clienteService.guardarGrupoCliente(datos).then((data) => {
            if (data.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Exito",
                detail: data.mensaje,
                life: 3000,
              });
              this.enviando = false;
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: data.mensaje,
                life: 3000,
              });
              this.enviando = false;
              this.detalleGrupoDesCliente();
            }
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "No se guardo el grupo de clientes",
            life: 3000,
          });
          this.enviando = false;
        },
      });
    },
    agregarClienteAlDetalle() {

      if (this.clienteSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un cliente",
          life: 3000,
        });
        return false;
      }
      let existe = this.detalleGrupoClientes.find((item) => {
        return item.id == this.clienteSelected.id;
      });

      if (existe) {
        this.errors.clienteSelected = ["El cliente ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El cliente ya esta en el grupo",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

        let detalle = {
          cliente_id: this.clienteSelected.id,
          nombre: this.clienteSelected.nombre,
          ci_nit: this.clienteSelected.ci_nit,
          telefono: this.clienteSelected.telefono,
        };

        this.detalleGrupoClientes.push(detalle);

        this.clienteSelected = null;
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.clienteListadoFiltrado = [...response.clientes];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    quitarClienteAlDetalle(slotProps) {
      this.detalleGrupoClientes.splice(slotProps.rowIndex, 1);
    },
    detalleGrupoDesCliente() {
      let datos = {
        grupo_cliente_descuentos_id: this.grupo_id,
      };
      this.clienteService
        .getGrupoDescCliente(datos)
        .then((response) => {
          this.detalleGrupoClientes = [...response.detalleGrupoDesCliente];
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
}
</style>